export const defaultUser = {
    id: null,
    name: "",
    email: "",
    password: "",
    role_id: 0,
    username: "",
    company_id: null,
};


export const defaultInvoice = {
    id: null,
    name: "",
    description: "",
    image: null,
    customer_id: null,
    vehicle_id: null,
    user_id: null,
    user: {
        name: ""
    }
};

export const defaultClient = {
    id: null,
    name: "",
    address: "",
    country: "",
    city: "",
    pib: "",
    pdv: "",
};

export const defaultVehicle = {
    id: null,
    name: "",
}

export const defaultCompany = {
    id: null,
    name: "",
    address: "",
    contact: "",
    email: "",
    pib: "",
    pdv: "",
    bank_account: "",
    image: null,
};
