export const usersPageHeaders = [
    {text: "Ime i prezime", value: "name"},
    {text: "Korisničko ime", value: "username"},
    {text: "Email", value: "email"},
    {text: "Uloga", value: "role.name"},
    {text: "Datum unosa", value: "created_at"},
    {text: "Akcije", value: "actions", sortable: false},
];
export const masterUsersPageHeaders = [
    {text: "Ime i prezime", value: "name"},
    {text: "Email", value: "email"},
    {text: "Uloga", value: "role.name"},
    {text: "Kompanija", value: "company.name"},
    {text: "Datum unosa", value: "created_at"},
    {text: "Akcije", value: "actions", sortable: false},
];

export const clientsHeaders = [
    {text: "Klijent", value: "name"},
    {text: "Adresa", value: "address"},
    {text: "Grad", value: "city"},
    {text: "Država", value: "country"},
    {text: "PIB", value: "pib"},
    {text: "PDV", value: "pdv"},
    {text: "Email", value: "email"},
    {text: 'Datum unosa', value: 'created_at'},
    {text: "Akcije", value: "actions", sortable: false, align: "center"},
];

export const vehiclesHeaders = [
    {text: "Vozilo", value: "name"},
    {text: "Datum unosa", value: "created_at"},
    {text: "Akcije", value: "actions", sortable: false, align: "center"},
];

export const companiesHeaders = [
    {text: "Kompanija", value: "name"},
    {text: "Adresa", value: "address"},
    {text: "PIB", value: "pib"},
    {text: "PDV", value: "pdv"},
    {text: "Kontakt", value: "contact"},
    {text: "Email", value: "email"},
    {text: "Datum unosa", value: "created_at"},
    {text: "Akcije", value: "actions", sortable: false, align: "center"},
];

export const invoiceHeaders = [
    {text: "Naziv", value: "name"},
    {text: "Ime i prezime", value: "user.name"},
    {text: "Vozilo", value: "vehicle.name"},
    {text: "Partner", value: "customer.name"},
    {text: "Datum", value: "date"},
    {text: "Datum unosa", value: "created_at"},
    {text: "Akcije", value: "actions", sortable: false, align: "center"},
];
